import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
import DATA from "../graphql/Devis/DATA.gql";
import NEWDEVIS from "../graphql/Devis/NEWDEVIS.gql";
import graphqlClient from "../utils/graphql";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  storage: window.sessionStorage,
  state: {
    search_text: null,
    newdevis: null,
    parametres: [],
    laizes: [],
    devis: {},
    devs: [],
    client_list: [],
    support_list: [],
    me: {},
    eitem: {},
    newclient: {}
  },
  mutations: {
    set_devis(state, devis) {
      state.devis = devis;
    },
    set_search_text(state, text) {
      state.search_text = text;
    },
    ActualUser(state, user) {
      state.me = user;
    },
    newdevis(state, newdevis) {
      state.newdevis = newdevis;
    },
    set_parametres(state, parametres) {
      state.parametres = parametres;
    },
    
    set_laizes(state, laizes) {
      state.laizes = laizes;
    },
    set_devs(state, devs) {
      state.devs = devs;
    },
    set_client_list(state, client_list) {
      state.client_list = client_list;
    },
    set_support_list(state, support_list) {
      state.support_list = support_list;
    },  
    new_client(state, newclient) {
      state.client_list.push(newclient);
      state.newclient = newclient;
    },
    update_client(state, client) {
      for (var i = 0; i < state.client_list.length; i++) {
        if (state.client_list[i].id == client.id) {          
          Vue.set(state.client_list, i, Object.assign({}, client));
          break;
        }
      }
    }
  },
  actions: {

    set_search_text(context, text) {
      context.commit("set_search_text", text);
    },
    ActualUser(context, user) {
      context.commit("ActualUser", user);
    },
    
    ActualItem(context, editeditem) {
      context.commit("ActualItem", editeditem);
    },
    ADD_CLIENT(context, newclient) {
      context.commit("new_client", newclient);
    },
    UPDATE_CLIENT(context, client) {
      context.commit("update_client", client);
    },
    async NewDevis(context) {
      context.commit("newdevis", "");
      await graphqlClient
        .query({
          query: NEWDEVIS,
          fetchPolicy: "network-only"
        })
        .then(data => {
          context.commit("newdevis", data.data.NewDevisCode);
        }) 
    },
    LoadDevis(context,devis) {
      context.commit("set_devis", devis);
    },
    LoadData(context) {
      graphqlClient
        .query({
          query: DATA
        })
        .then(data => {
          context.commit("set_client_list", data.data.devisclients);
          context.commit("set_support_list", data.data.supports);
          context.commit("set_devs", data.data.developpements);
          context.commit("set_laizes", data.data.laizes);
          context.commit("set_parametres", data.data.parametres);
          context.commit("newdevis", data.data.NewDevisCode);          
        });
    }
  },
  modules: {}
});
