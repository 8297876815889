//import { from } from 'core-js/fn/array'
import Vue from "vue";
import VueRouter from "vue-router";
import { isLoggedIn } from "../vue-apollo.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Login.vue"),
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/",
    component: () => import("../views/MainLayout.vue"),

    children: [
      {
        path: "/devis",
        component: () => import("../views/Devis.vue"),
        name: "devis",
        params:true
      },
      {
        path: "/listedevis",
        component: () => import("../views/ListeDevis.vue")
      },
      {
        path: "/clients",
        component: () => import("../views/Listeclient.vue")
      },
      
      { path: "*", component: () => import("../components/NotFound.vue") }
    ]
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: "/",
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
});

export default router;

