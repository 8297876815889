<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
//const AUTH_TOKEN = "apollo-token";
//const CACHE_VUEX = "vuex";
export default {
  name: "App",

  components: {},

  data: () => ({}),
  mounted() {
    // Clear the browser cache data in localStorage when closing the browser window
    window.onbeforeunload = function() {
      //localStorage.removeItem(AUTH_TOKEN);
      //localStorage.removeItem(CACHE_VUEX);
      //var storage = window.localStorage;
      //storage.clear()
    };
  }
};
</script>
